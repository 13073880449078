import { useGlobalContext } from "@src/context/globalContext";
import {
  TextSmallcapsIcon,
  ViewIcon,
  ViewOffSlashIcon,
} from "@src/features/polotno/component/hugeIcons/hugeIcons";
import { Input } from "antd";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

const TextOverlayTab = ({ store }: any) => {
  const [textoverlay, setTextOverlay] = useState("");
  const [textOverlayId, setTextOverlayId] = useState("");
  const [activePageId, setActivePageId] = useState("");
  const [elementId, setElementId] = useState("");
  const [element, setElement] = useState<any>({});
  const [sceneElementType, setElementType] = useState("");
  const childs: any = [];

  const {
    ScriptDetails: [scriptDetails, setScriptDetails],
    ShowTextOverlay: [showTextOverlay, setShowTextOverlay],
    ActiveIndex: [activeIndex, setActiveIndex],
    ActiveElementId: [activeElementId, setActiveElementId],
    ActiveSearchQueries: [activeSearchQueries, setActiveSearchQueries],
  } = useGlobalContext();
  useEffect(() => {
    const storeJson = store.toJSON();
    if (
      storeJson.pages.length > 0 &&
      scriptDetails &&
      scriptDetails.data[scriptDetails.data.length - 1].captions
    ) {
      //store.setScale(0.25);
      setActivePageId(store.activePage.id);
      store.pages.map((page: any, index: any) => {
        if (page.id === store.activePage.id) {
          setActiveIndex(store.activePage.custom.scriptIndex || index);
        
          setActiveSearchQueries(
            scriptDetails.data[store.activePage.custom.scriptIndex || index]
              ?.searchQueries ||
              scriptDetails.data[store.activePage.custom.scriptIndex || index]
                ?.images[0].searchQueries
          );
        }
      });

      setActiveElementId(store.activePage?.children[0].id);
      store.activePage?.children?.map((child: any, index: any) => {
        childs.push(child.type);
        if (child.name === "h1-notlinked") {
          setTextOverlay(child.text);
          setTextOverlayId(child.id);
        }
        if (child.type == "video") {
          setElement(child);

          setElementId(child.id);
          setElementType("video");
          setActiveElementId(child.id);
        }
      });
      if (!childs.includes("video")) {
        setElement({});

        setElementId("");
        setElementType("image");
      }
    }
  }, [store.activePage, store, scriptDetails]);
  const handleTextOverlayChange = (e: any) => {
    setTextOverlay(e.target.value);
    store.getElementById(textOverlayId).set({
      text: e.target.value,
    });
  };

  const colStyle: React.CSSProperties = {
    backgroundColor: "var(--color-primary-100)",
    borderRadius: "10px",
    height: "100%", // Ensure the columns stretch to the row's height
  };
  const handleTextOverlayToggle = (status: boolean) => {
    setShowTextOverlay(status);

    store.pages.map((page: any) => {
      page.children.map((child: any) => {
        if (child.name === "h1-notlinked") {
          const elementID = child.id;
          if (store.getElementById(elementID)) {
            store.getElementById(elementID).set({
              visible: status,
              opacity: status ? 1 : 0,
            });
          }
        }
      });
    });
    // }
  };
  return (
    <div style={{   paddingTop: "15px"}}>
 
      <div
        style={{
          ...colStyle,
          display: "flex",
          alignItems: "center",
          height: "100%", // Ensure the inner div also takes full height
          padding: "5px",
       
        }}
      >
        <div
          style={{
            marginRight: "10px",
            backgroundColor: "var(--color-primary-200)",
            borderRadius: "10px",
            height: "100%",
            display: "flex", // Use flexbox for centering
            justifyContent: "center", // Horizontally center the text
            alignItems: "center", // Vertically center the text
          }}
        >
          {/* <h2 style={{ margin: 0, fontSize: "12px", padding: "15px" }}>
                {textoverlay}
              </h2> */}
          <Input
            value={textoverlay}
            onChange={handleTextOverlayChange}
            // onChange={(e) => handleInputChange(e)}
            // style={{ margin: 0, fontSize: "12px", padding: "5px" }}
            style={{
              margin: 0,
              fontSize: "12px",
              padding: "15px", // Match padding of h2
              border: "none", // Remove border
              outline: "none", // Remove outline on focus
              backgroundColor: "transparent", // Match the background
              borderRadius: "10px", // Match the rounded corners
              width: "auto", // Auto width to fit text length
              minWidth: "50px", // Optional: Minimum width to avoid collapsing
            }}
            autoFocus
            // autoFocus
          />
        </div>
      </div>
    </div>
  );
};

export default observer(TextOverlayTab);
