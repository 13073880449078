import { getDomainID, qaBackendApiInstance } from '../config/config'
import { getVideoPreview } from 'polotno/utils/video'
import { useMutation, useQuery } from 'react-query'

const getVideoFilePreview = async (videosData: any) => {
  const videosPreview = Promise.all(
    videosData.items.map(async (video: any) => {
      if(video.type === 'video'){
        const preview = await getVideoPreview(video.video_url)
        return { ...video, image_url: preview }
      }
      return { ...video, image_url: video.image_url }
    })
  )
  return videosPreview
}

// for staff names
export const useGetBGMusic = (page: number, type: string) => {
  return useQuery(
    [page, type, 'bg'],
    async () => {
      const assets = await qaBackendApiInstance
        .get('/asset/all', {
          params: {
            domain_id: getDomainID(),
            page: page,
            page_size: 15,
            assets_type: type,
            sub_type: 'bg'
          },
        })
        .catch((error) => {
          throw error
        })
    
      if (type === 'video') {
        const videosPreview = await getVideoFilePreview(assets.data)
        return { ...assets.data, items: videosPreview }
      }

      return assets?.data
    },
    { retry: false }
  )
}
