
import { useMutation } from "react-query"; // Updated to react-query

import { AxiosError } from "axios";
import { NODE_ENV } from "@src/config";
import { getProtectedAxios } from "../auth";
import { QA_BACKEND_URL } from "@src/config/config";

// Define the log data type
interface LogData {
  module: string;
  message: string;
  project_id: number;
  type: string;
}

// Define the response type
interface LogResponse {
  success: boolean;
  data: any;
}

const suburl= NODE_ENV === 'prod' ? '/logs/add-logs' : 'logs/add-logs'

// Function to handle the API request
export async function postLog(logData: LogData): Promise<LogResponse> {
  const axios = getProtectedAxios(); // Get protected Axios instance
  const endpoint =QA_BACKEND_URL + suburl;

  return axios
    .post(endpoint, logData)
    .then(({ data }) => data)
    .catch((error: AxiosError) => {
     
      throw error; // Re-throw to let React Query handle the error
    });
}

// Custom hook using useMutation
export default function useSaveLog() {
  const mutation = useMutation<LogResponse, AxiosError, LogData>(postLog, {
    onError: (error) => {
     console.error(error)
    },
  });

  return {
    mutate: mutation.mutate, // Function to trigger the mutation
    data: mutation.data, // Data returned from the API
    isLoading: mutation.isLoading, // Loading state
    error: mutation.error, // Error state
    isSuccess: mutation.isSuccess, // Success state
  };
}
