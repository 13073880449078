import { PEXELS_KEY, NODE_BACKEND_URL } from '../../../../config';
import axios, { AxiosRequestConfig } from 'axios';

import { getDomainID, qaBackendApiInstance } from '../../../../config/config';
import { message } from 'antd';
import { setCookie } from 'cookies-next';

const assetIds: any[] = [];
export async function getPexelsVideos(searchQuery: string) {
  const res = await axios
    .get('https://api.pexels.com/videos/search', {
      headers: {
        Authorization: PEXELS_KEY,
      },
      params: {
        query: searchQuery,
        orientation: 'landscape',
        size: 'medium',
      },
    })
    .then((response) => {
      const videoResult = response?.data?.videos[0];
      if (!videoResult) {
        return -1;
      }
      const videos = videoResult.video_files.filter(
        (file: { quality: string }) => file.quality === 'sd'
      );
      const videoUrl = videos[videos.length - 1].link;
      return videoUrl;
    })
    .catch((error) => {
      console.error(error);
      return -1;
    });

  return res;
}

export async function getGettyVideos(
  searchQuery: string,
  orientations: string
) {
  const currentDate = new Date().toISOString().split('T')[0];

  const res = await axios
    .get(`${NODE_BACKEND_URL}api/v1/gettyImage/videoSearch`, {
      params: {
        searchQuery: searchQuery,
        isFirstRequest: false,
        orientations: orientations,
      },
    })
    .then(async (response) => {
      const videoResult = response?.data?.videos[0];
      if (!videoResult) {
        return -1;
      }
      const videos = videoResult.display_sizes.find(
        (displaySize: { name: string }) => displaySize.name === 'comp'
      );
      const videoUrl = videos.uri;
      // console.log(videoUrl)
      // const videoUrl = 'https://delivery.gettyimages.com/downloads/2012532915?k=20&e=H0bqKmuQadraqsoxLdIP8aq6_cGQHjP6kFGAV_39IexChCj9I1x0Afzbg_nHrrGeBKrzndtltul2pieopPnNUTmLKRtJHAQIk_GJARuZUNh46WkYjd3Fzlb4LVG1zNam'
      // const hdUri= await GettyVideoDownloadSizes(videoResult.id);
      // console.log(hdUri)
      // if(hdUri){
      //   return hdUri
      // }
      try {
        assetIds.push({
          asset_id: videoResult.id,
          quantity: 1,
          usage_date: currentDate,
        });
        // await gettyAssetTrack(videos.id, 1)
        JSON.stringify(assetIds);
        setCookie('gettyIds', JSON.stringify(JSON.stringify(assetIds)));
      } catch (error) {
        console.error('Failed to track');
      }

      return videoUrl;
    })
    .catch((error) => {
      console.error(error);
      return -1;
    });
  return res;
}

export async function GettyVideoDownloadSizes(
  id: string
): Promise<any | undefined> {
  const res = await axios
    .get(`${NODE_BACKEND_URL}api/v1/gettyImage/Video-download-sizes`, {
      params: {
        assetId: id,
        size: 'hd16',
      },
    })
    .then((response) => {
      const uri = response.data || '';
      if (uri) {
        return uri;
      }

      return undefined;
    });
  return res;
}

export async function getGettyImages(
  searchQuery: string,
  orientations: string,
  isFirstRequest?: boolean
) {
  const graphicalStyle = localStorage.getItem('graphicalStyle');
  const currentDate = new Date().toISOString().split('T')[0];
  // const firstFourKeywords = searchQuery.split(' ').slice(0, 4).join(' ');
  const res = await axios

    .get(`${NODE_BACKEND_URL}api/v1/gettyImage/imageSearch`, {
      params: {
        searchQuery: searchQuery,
        // isFirstRequest: isFirstRequest ? isFirstRequest : true,
        isFirstRequest: false,
        orientations: orientations,
        graphicalStyle: graphicalStyle || '',
      },
    })
    .then(async (response) => {
      const resultLength =
        response?.data?.length > 5 ? 5 : response?.data?.length;
      const randomIndex = Math.floor(Math.random() * resultLength) || 0;
      const imagesResult = response?.data?.[0];
      if (!imagesResult) {
        return 'https://media.gettyimages.com/id/1336887259/photo/row-of-flowering-trees-during-spring-season-in-lalbagh-botanical-garden-bengaluru.jpg?b=1&s=612x612&w=0&k=20&c=-g4hr6bFV6cqDLOxtYVuflIer8iWvojMZ7QepQafnpk=';
      }
      const images = imagesResult.display_sizes.find(
        (displaySize: { name: string }) => displaySize.name === 'comp'
      ); // chnage
    
      const imageUrl = images.uri;
      // const hdUri : string | undefined = await GettyImageDownloadSizes(imagesResult.id);
      // if(hdUri){
      //   return hdUri
      // }
      try {
        assetIds.push({
          asset_id: imagesResult.id,
          quantity: 1,
          usage_date: currentDate,
        });
        // await gettyAssetTrack(imagesResult.id, 1)
        JSON.stringify(assetIds);
        setCookie('gettyIds', JSON.stringify(JSON.stringify(assetIds)));
      } catch (error) {
        console.error('Failed to track');
      }

      return imageUrl;
    })
    .catch((error) => {
      console.error(error);
      return 'https://media.gettyimages.com/id/1336887259/photo/row-of-flowering-trees-during-spring-season-in-lalbagh-botanical-garden-bengaluru.jpg?b=1&s=612x612&w=0&k=20&c=-g4hr6bFV6cqDLOxtYVuflIer8iWvojMZ7QepQafnpk=';
    });
  return res;
}

export async function gettyAssetTrack(Ids: string, quantity: number) {
  const res = await axios
    .post(`${NODE_BACKEND_URL}api/v1/gettyImage/track-getty-asset`, {
      assetId: Ids,
      quantity: quantity,
    })
    .then((response) => {
      return response;
    });
  return res;
}
export async function GettyImageDownloadSizes(
  id: string
): Promise<any | undefined> {
  const res = await axios
    .get(`${NODE_BACKEND_URL}api/v1/gettyImage/Image-download-sizes`, {
      params: {
        assetId: id,
      },
    })
    .then((response) => {
      console.error(response?.data?.uri);
      const uri = response?.data?.uri || '';
      if (uri) {
        return uri;
      }
      return undefined;
    });
}

export async function getGettyVideosTest(
  searchQuery: string,
  orientations: string,
  image_techniques: string[]
) {
  const currentDate = new Date().toISOString().split('T')[0];
  const firstFourKeywords = searchQuery.split(' ').slice(0, 4).join(' ');

  const res = await axios
    .get(`${NODE_BACKEND_URL}api/v1/gettyImage/videoSearch`, {
      params: {
        searchQuery: firstFourKeywords,
        isFirstRequest: false,
        orientations: orientations,
        image_techniques: image_techniques
      },
    })
    .then(async (response) => {
      const resultLength =
        response?.data?.videos?.length > 5 ? 5 : response?.data?.videos?.length;
      const randomIndex = Math.floor(Math.random() * resultLength) || 0;
      const videoResult = response?.data?.videos?.[0];
      if (!videoResult) {
        return -1;
      }
      const videos = videoResult.display_sizes.find(
        (displaySize: { name: string }) => displaySize.name === 'comp'
      );
      const videoUrl = videos.uri;
      // const videoUrl = 'https://delivery.gettyimages.com/downloads/2012532915?k=20&e=H0bqKmuQadraqsoxLdIP8aq6_cGQHjP6kFGAV_39IexChCj9I1x0Afzbg_nHrrGeBKrzndtltul2pieopPnNUTmLKRtJHAQIk_GJARuZUNh46WkYjd3Fzlb4LVG1zNam'
      // const hdUri= await GettyVideoDownloadSizes(videoResult.id);
      // console.log(hdUri)
      // if(hdUri){
      //   return hdUri
      // }
      const obj = { uri: videoUrl, id: videoResult.id };
      try {
        assetIds.push({
          asset_id: videoResult.id,
          quantity: 1,
          usage_date: currentDate,
        });
        // await gettyAssetTrack(videoResult.id, 1)
        JSON.stringify(assetIds);
        setCookie('gettyIds', JSON.stringify(JSON.stringify(assetIds)));
      } catch (error) {
        console.error('Failed to track');
      }
      return obj;
    })
    .catch((error) => {
      console.error(error);
      return -1;
    });
  return res;
}

// export async function aiImagesAPI(searchQuery: string, orientations: string) {
//   const formData = new FormData();
//   formData.append('prompt', searchQuery);
//   formData.append('n_images', '1');
//   // formData.append('image_name', searchQuery.slice(0,6).replace(/\s+/g, ''))
//   formData.append('image_name', generateUniqueId());
//   const config: AxiosRequestConfig = {
//     headers: {
//       'Content-Type': 'multipart/form-data',
//     },
//   };
//   try {
//     const res = await axios.post(
//       `https://imgbd.virtualshoots.ai/api/sd_focus_animated_q`,
//       formData,
//       config
//     );
//     console.log('Response:', res.data);
//     const result = res.data.background_image;

//     if (!result) {
//       try {
//         // ------ to do ----- //
//         // add pending task to the remainingTasks array
//         //---------------------//

//         await axios.get('https://imgbd.virtualshoots.ai/api/clear-gpu');
//       } catch (error) {
//         return -1;
//       }
//       return -1;
//     }
//     try {
//       return result;
//     } catch (error) {
//       console.error('Error converting image:', error);
//       try {
//         await axios.get('https://imgbd.virtualshoots.ai/api/clear-gpu');
//       } catch (error) {
//         return -1;
//       }
//     }
//     // return result
//   } catch (error) {
//     console.error('Error:', error);
//     try {
//       await axios.get('https://imgbd.virtualshoots.ai/api/clear-gpu');
//     } catch (error) {
//       return -1;
//     }
//   }
// }

function getRandomNumber(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

async function uploadFile(file: File): Promise<any> {
  // const type = getType(file)

  const type = 'image';
  const formData = new FormData();
  formData.append('file', file);
  formData.append('asset_type', 'image');
  try {
    const response = await qaBackendApiInstance.post(
      `/asset/upload?asset_type=${type}&domain_id=${getDomainID()}`,
      formData
    );
    if (response.status === 200) {
      // console.log('File uploaded successfully', response.data);
      return response.data.image_url;
    } else {
      message.error('Upload failed with status: ' + response.status);
    }
  } catch (error:any) {
    if (
      error.code === 504 ||
      error.code === 'ECONNABORTED' ||
      error.code === 'ECONNRESET' ||
      error.code === 'ETIMEDOUT' ||
      error.code === 'ERR_NETWORK'
    ) {
      console.log('File Uploaded successfully.');
    }
    console.error('Error uploading file:', error);
    message.error('Upload failed.');
  }
}

export async function generateDalleImage(
  searchQuery: string,
  orientations: string
) {
  // const size = orientations === 'vertical' ? '1024x1792' : '1792x1024';
  let size;

  if (orientations === 'vertical') {
    size = '9:16';
  } else if (orientations === 'horizontal') {
    size = '16:9';
  } else {
    size = '1:1';
  }
  const randomNumber = getRandomNumber(1184522, 9999999);
  const postBody = {
    prompt: `Generate a hyper-detailed 4K quality, sharp, and ultra-realistic scene of ${searchQuery}. The scene should be crafted using good aesthetics, featuring ultra-fine detailing with deep shadows and vibrant highlights. Capture the setting with a wide-angle perspective and cinematic framing, emphasizing the high-tech elements throughout. Ensure the image has HDR quality, with rich textures and contrasts that bring out the realism and intensity of the scene.`,
    steps: 4,
    seed: 5138230,
    aspect_ratio: size,
    base64: true,
  };
  try {
    const localEndPoint = axios.create({
      baseURL: `${NODE_BACKEND_URL}`,
    });
    const response = await localEndPoint.post(
      '/api/v1/segmind/fast-flux-schnell',
      postBody
    );
   
    // const url = 'https://api.segmind.com/v1/fast-flux-schnell';
    // const response = await axios.post(url, postBody, {
    //   headers: { 'x-api-key': api_key },
    // });
    const binaryData = response.data.image;
    const finalFile = convertBinaryToImageFile(
      binaryData,
      'schnell.png',
      'image/png'
    );
    const uploadedUrl = await uploadFile(finalFile);
    return uploadedUrl;
  } catch (error) {
    console.error(error);
    return undefined;
  }
}

export async function aiImagesAPI(searchQuery: string, orientations: string, prefix='') {
  // const size = orientations === 'vertical' ? '1024x1792' : '1792x1024';
  let searchPrompt:string
  const storedSize = localStorage.getItem('aspect_ratio')
 
  if(prefix.length>1){
    searchPrompt=`${searchQuery} ${prefix}`
  }else{
    // searchPrompt=`Create a 4K quality, sharp, and ultra-realistic scene of ${searchQuery}. The animation should be designed using colorfull aesthetics, featuring ultra-fine detailing, deep shadows, and highlights. Utilize a wide-angle perspective and cinematic framing to enhance the dynamic movement, incorporating high-tech elements that interact with the environment. Ensure the animation has HDR quality with smooth transitions, rich textures, and realistic lighting effects to bring the scene to life in a captivating, visually stunning manner.`
    searchPrompt = `${searchQuery} Ultra realistic, 4K quality, sharp, with natural conditions. Use colorful aesthetics, featuring ultra-fine detailing, deep shadows, and highlights. Utilize a wide-angle perspective and cinematic framing to enhance the dynamic movement. Realistic lighting effects to bring the scene to life in a captivating, visually stunning manner.`
  }
  let size;
 
  if (orientations === 'vertical') {
    size = '9:16';
  } else if (orientations === 'horizontal' && storedSize!='1:1') {
    size = '16:9';
  } else {
    size = '1:1';
  }
  const randomNumber = getRandomNumber(1184522, 9999999);
  const postBody = {
    prompt: searchPrompt,
    steps: 4,
    seed: 5138233,
    aspect_ratio: size,
    base64: true,
  };
  try {
    const localEndPoint = axios.create({
      baseURL: `${NODE_BACKEND_URL}`,
    });
    const response = await localEndPoint.post(
      '/api/v1/segmind/fast-flux-schnell',
      postBody
    );
    // const url = 'https://api.segmind.com/v1/fast-flux-schnell';
    // const response = await axios.post(url, postBody, {
    //   headers: { 'x-api-key': api_key },
    // });
   
    const binaryData = response.data.image;
    const finalFile = convertBinaryToImageFile(
      binaryData,
      'schnell.png',
      'image/png'
    );
    const uploadedUrl = await uploadFile(finalFile);
    return uploadedUrl;
  } catch (error) {
    console.error(error);
    return undefined;
  }
}

export async function generateDalleImage_real(
  searchQuery: string,
  orientations: string
) {
  const size = orientations === 'vertical' ? '1024x1792' : '1792x1024';

  const postBody = {
    prompt: searchQuery,
    size: size,
  };
 
  const localEndPoint = axios.create({
    baseURL: `${NODE_BACKEND_URL}`,
  });
  try {
    const response = await localEndPoint.post(
      '/api/v1/openAi/dalle-image',
      postBody
    );
    // console.log(response.data)
    const params = {
      image_url: response.data,
      domain_id: getDomainID(),
    };
   
    const token = localStorage.getItem('accessToken');
    const uploadAsset = await qaBackendApiInstance.post(
      `/asset/upload-from-url`,
      params,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  
    return uploadAsset.data.image_url;
  } catch (error) {
    return undefined;
  }
}

// export async function aiImagesAPI_old(searchQuery: string, orientations:string) {
//   const size = orientations === 'vertical' ? '1024x1792' : '1792x1024'

//   const postBody = {
//     prompt: searchQuery,
//     size:size
//   }
//   const localEndPoint = axios.create({
//     baseURL: `${NODE_BACKEND_URL}`,
//   })
//   try{
//     const response = await localEndPoint.post('/api/v1/openAi/dalle-image', postBody)
//     // console.log(response.data)
//     const params={
//         image_url : response.data,
//         domain_id : getDomainID()
//     }

//     const token = localStorage.getItem('accessToken')
//     const uploadAsset = await qaBackendApiInstance.post(
//       `/asset/upload-from-url`,
//       params,
//       {
//         headers: {
//             'Authorization': `Bearer ${token}`
//         }
//     }
//     )
//     console.log(uploadAsset.data.image_url)
//     return uploadAsset.data.image_url

//   }catch(error:any){

//     return undefined
//   }

// }

export async function voiceCloneAPI(name: any, descripton: any, files: any) {
  const formData = new FormData();
  formData.append('name', name);
  formData.append('description', '');
  // formData.append('labels', JSON.stringify(labels));

  files.forEach((file: any, index: any) => {
    formData.append(`file${index}`, file);
  });
  const localEndPoint = axios.create({
    baseURL: `${NODE_BACKEND_URL}`,
  });

  try {
    const response = await localEndPoint.post(
      '/api/v1/elevenlabs/voice-clone',
      formData
    );
   
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export async function creditsDeduct(type: string, credits: number) {
  const domainID = getDomainID();
  const token = localStorage.getItem('accessToken');

  try {
    const response = await qaBackendApiInstance.post(
      `/user/credits?domain_id=${domainID}&type=${type}&credit=${credits}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return -1;
  }
}

export async function getUserDetails() {
  const token = localStorage.getItem('accessToken');
  const domain_id = getDomainID()
  try {
    const response = await qaBackendApiInstance.get(`/user/details?domain_id=${domain_id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    return -1;
  }
}
export async function GettyVideoDetail(
  id: string
): Promise<any | undefined> {
  const res = await axios
    .get(`${NODE_BACKEND_URL}api/v1/gettyImage/video-detail`, {
      params: {
        assetId: id,
        size: 'hd16',
      },
    })
    .then((response) => {
      const uri = response.data || '';
      if (uri) {
        return uri;
      }

      return undefined;
    });
  return res;
}
'https://delivery.gettyimages.com/downloads/1434565290?k=20&e=Pr8FJ7DQ_EdxRh81kZ6HlBKZF_avOm-9_vOWVXXfxCRugJzM93EnJsLMTJXePYKQCmd_OkE_tP0VbeqpcrskUrkX4DAcGd0BEREGAFHnqcQ='
export async function GettyImageDetail(
  id: string
): Promise<any | undefined> {
  const res = await axios
    .get(`${NODE_BACKEND_URL}api/v1/gettyImage/image-detail`, {
      params: {
        assetId: id,
        // size: 'hd16',
      },
    })
    .then((response) => {
      const uri = response.data || '';
      if (uri) {
        return uri;
      }

      return undefined;
    });
  return res;
}



function convertBinaryToImageFile(
  binaryData: string,
  fileName: string,
  mimeType: string
): File {
  const byteCharacters = atob(binaryData);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: mimeType });

  return new File([blob], fileName, { type: mimeType });
}

export async function GettyQualityImage(
  id: string
): Promise<any | undefined> {
  const res = await axios
    .get(`${NODE_BACKEND_URL}api/v1/gettyImage/quality-image`, {
      params: {
        assetId: id,
      },
    })
    .then((response) => {
      const uri = response.data || '';
      if (uri) {
        return uri;
      }

      return undefined;
    });
  return res;
}

export const updateWaterMarkVideos = async (store:any) =>{
  for (const page of store.pages) {
    for (const child of page.children) {
      if (child.type === 'video') {
        const element = store.getElementById(child.id);
        const obj = { id: child.id, src: child.src };

        if (element) {
          if (child.custom) {
            try {
             
              const id = extractId(child.src);
              if(id){
                const waterMarkedUrl = await GettyVideoDetail(
                  id || child.custom.gettyId);
                 
                 if (waterMarkedUrl) {
                   const videos = waterMarkedUrl.display_sizes.find(
                     (displaySize: { name: string }) => displaySize.name === 'comp'
                   );
                  
                   element.set({ src: videos.uri});
                   if(id){
                     element.set({custom:{...child.custom, gettyId:id} });
                   }
                 }
              }
            
            } catch (error) {
              console.error(
                `Failed to get water marked URL for id ${child.custom.gettyId}: `,
                error
              );
              return undefined
            }
          }
        }
      } else if(child.type == 'image') {
        const element = store.getElementById(child.id);
        const obj = { id: child.id, src: child.src };
       
        if (element) {
          // if (child.custom) {
            try {
            
              const id = extractId(child.src);
            
              if(id){
                const waterMarkedUrl = await GettyImageDetail(
                  id || child.custom.gettyId);
                 
                 if (waterMarkedUrl) {
                 
                   const videos = waterMarkedUrl.images?.[0].display_sizes.find(
                     (displaySize: { name: string }) => displaySize.name === 'comp'
                   );
                
                   element.set({ src: videos.uri});
                   if(id){
                     element.set({custom:{...child.custom, gettyId:id} });
                   }
                 }
              }
             
            } catch (error) {
              console.error(
                `Failed to get water marked URL for id ${child.custom.gettyId}: `,
                error
              );
              return undefined
            }
          // }
        }
      }
    }
  }
}

function extractId(url: string): string | null {
  const regex = /\/downloads\/(\d+)\?/; // Regular expression to match the ID
  const match = url.match(regex);      // Perform the match
  return match ? match[1] : null;      // Return the matched ID or null if no match
}



// Usage example:


// function convertbase64ToImageFile(
//   base64: string,
//   fileName: string,
//   mimeType: string
// ): File {
//   const byteCharacters = atob(base64);
//   const byteNumbers = new Array(byteCharacters.length);

//   for (let i = 0; i < byteCharacters.length; i++) {
//     byteNumbers[i] = byteCharacters.charCodeAt(i);
//   }

//   const byteArray = new Uint8Array(byteNumbers);
//   const blob = new Blob([byteArray], { type: mimeType });

//   return new File([blob], fileName, { type: mimeType });
// }