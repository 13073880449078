// utils.ts
export const preloadVideo = (url: string): Promise<boolean> => {
    return new Promise((resolve) => {
   
      const video = document.createElement('video');
  
      video.preload = 'auto';
      video.src = url;
  
      const onCanPlayThrough = () => {
        resolve(true);
        cleanup();
      };
  
      const onError = () => {
        resolve(false);
        cleanup();
      };
  
      const cleanup = () => {
        video.removeEventListener('canplaythrough', onCanPlayThrough);
        video.removeEventListener('error', onError);
      };
  
      video.addEventListener('canplaythrough', onCanPlayThrough);
      video.addEventListener('error', onError);
    });

  };
  