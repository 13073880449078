/* eslint-disable react/jsx-key */
import {
  LoadingOutlined,
  ReloadOutlined,
  DownCircleOutlined,
  UpCircleOutlined,
  HighlightOutlined,
} from "@ant-design/icons";
import { useGlobalContext } from "../../../context/globalContext";
import { FONT_TEMPLATE } from "../../../data/captions";
import { generateCaptions } from "../../../shared/utils/VideoGeneration/audioGeneration";
import { Button, Col, Row, Switch, message, Alert, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import "./captionsPanel.less";
import FontPickerRow from "./rows/fontPickerRow";
import ChooseFontTemplatesRow from "./rows/fontStyleRow";
import PositionSliderRow from "./rows/positionSliderRow";
import WordSlider from "./rows/wordSliderRow";
import { generateSubtitles } from "../../../shared/utils/captions";
import EditCaptions from "./rows/captionEditRow";
import { SectionTab } from "polotno/side-panel";
import { Message01Icon } from "@src/features/polotno/component/hugeIcons/hugeIcons";

interface CaptionsPanelProps {
  store: any;
}

export const CaptionsPanel: React.FC<CaptionsPanelProps> = React.memo(
  ({ store }: CaptionsPanelProps) => {
    const {
      ScriptDetails: [, setScriptDetails],
      RightSideBar: [activeComponent, setActiveComponent],
      UserDetail: [userDetail, setUserDetail],
      ActiveSidePanel: [activeSidePanel, setActiveSidePanel],
    } = useGlobalContext();
    useEffect(()=>{
      setActiveSidePanel(store.openedSidePanel)
    },[])
    const [showAdvanced, setShowAdvanced] = useState(false);
    const [showColorSetting, setShowColorSetting] = useState(false);
    const [captionsActiveIndex, setCaptionsActiveIndex] = useState<
      number | null
    >(null);
    const [selectedFont, setSelectedFont] = useState(
      store.pages[0].custom?.template?.font
        ? store.pages[0].custom?.template.font
        : FONT_TEMPLATE[0].font
    );
    const [wordsPerLine, setWordsPerLine] = useState(
      store.pages[0].custom?.template
        ? store.pages[0].custom?.template.wordsPerLine
        : FONT_TEMPLATE[0].wordsPerLine
    );

    const [yAxisPosition, setYAxisPosition] = useState(
      store.height - store.height * 0.2
    );
    const [fontTemplate, setFontTemplate] = useState<any>(
      store.pages[0].custom?.template
        ? store.pages[0].custom?.template
        : { ...FONT_TEMPLATE[0], position: store.height - store.height * 0.2 }
    );
    const [fontSize, setFontSize] = useState(
      store.pages[0].custom?.template
        ? store.pages[0].custom?.template.fontSize
        : FONT_TEMPLATE[0].fontSize
    );
    const [showSubtitles, setShowSubtitles] = useState<boolean>(true);

    const [showTryLaterMessage, setShowTryLaterMessage] = useState(false);
    const [regenrateButtonIcon, setRegenrateButtonIcon] = useState(false);

    const handleIconClick = (index: number) => {
      setCaptionsActiveIndex(index);
    };

    // useEffect(() => {
    //   console.log(selectedFont)
    // },[selectedFont])

    useEffect(() => {
      store.pages.map((page: any) => {
        if (!page?.custom?.captions || !(page?.custom?.captions?.length > 0)) {
          setShowTryLaterMessage(true);
        } else {
          if (!page?.custom)
            page.set({
              custom: { ...page.custom, template: FONT_TEMPLATE[0] },
            });
          if (!page?.custom?.template)
            page.set({
              custom: { ...page.custom, template: FONT_TEMPLATE[0] },
            });
        }
      });
      if (showSubtitles === true) {
        setScriptDetails((prevScript: { data: any }) => {
          const updatedScript = { ...prevScript };

          return { ...updatedScript, showSubtitles: true };
        });
        store.pages.map((page: any) => {
          const elementID = "captionText" + page.id;
          page.set({ custom: { ...page.custom, showSubtitles: true } });
          if (store.getElementById(elementID)) {
            store.getElementById(elementID).set({
              opacity: 1,
              backgroundEnabled: true,
            });
          }
        });
      }
    }, []);

    useEffect(() => {
      if (store.pages[0].cutom?.template?.name !== fontTemplate.name) {
        generateSubtitles(
          undefined,
          undefined,
          undefined,
          store,
          wordsPerLine,
          fontTemplate,
          showSubtitles,
          yAxisPosition,
          setYAxisPosition,
          setScriptDetails
        );
        setFontSize(fontTemplate.fontSize);
        setWordsPerLine(fontTemplate.wordsPerLine);
        setSelectedFont(fontTemplate.font);
      }
    }, [fontTemplate]);

    async function regenerateSubtitles() {
      setRegenrateButtonIcon(true);
      let captionsResult;
      await Promise.all(
        store.pages.map(async (page: any, index: number) => {
          // const audioUrl = page?.custom?.sounds[0]?.url;
          const audioUrl =
            Array.isArray(page?.custom?.sounds) && page.custom.sounds.length > 0
              ? page.custom.sounds[0].url
              : undefined;
          if (!audioUrl) {
            message.info("Audio not found");
            return;
          }
          captionsResult = await generateCaptions(
            audioUrl,
            userDetail?.purpose?.language || ""
          );
          if (captionsResult) {
            store.pages[index].set({
              custom: {
                ...store.pages[index].custom,
                captions: [{ words: captionsResult?.data?.words }],
              },
            });
          }
        })
      );
      if (captionsResult) {
        generateSubtitles(
          undefined,
          undefined,
          undefined,
          store,
          wordsPerLine,
          fontTemplate,
          showSubtitles,
          yAxisPosition,
          setYAxisPosition,
          setScriptDetails
        );
        setShowTryLaterMessage(false);
      } else {
        message.error("Regeneration Failed");
        setRegenrateButtonIcon(false);
        setShowTryLaterMessage(true);
      }
    }

    const setFontTemplates = (name: any) => {
      const template = FONT_TEMPLATE.find((template) => template.name === name);
      setFontTemplate(template);
    };

    const updateSubtitleYAxis = (yAxisPosition: number) => {
      store.pages.map((page: any) => {
        const elementID = "captionText" + page.id;
        setYAxisPosition(yAxisPosition);
        // console.log(elementID)
        // 
        store.getElementById(elementID).set({
          y: yAxisPosition,
        });
        page.set({
          custom: {
            ...page.custom,
            template: { ...page.custom.template, position: yAxisPosition },
          },
        });
      });

      setScriptDetails((prevScript: { template: any }) => {
        const updatedScript = { ...prevScript };

        let updatedTemplate = { ...prevScript.template };

        updatedTemplate = {
          ...updatedTemplate,
          position: yAxisPosition,
        };

        return { ...updatedScript, template: updatedTemplate };
      });
    };

    const updateFontSize = (fontSize: number) => {
      setFontSize(fontSize);
      generateSubtitles(
        selectedFont,
        wordsPerLine,
        fontSize,
        store,
        wordsPerLine,
        fontTemplate,
        showSubtitles,
        yAxisPosition,
        setYAxisPosition,
        setScriptDetails
      );
    };

    const updateWordsPerLine = (wordsPerLine: number) => {
      setWordsPerLine(wordsPerLine);
      generateSubtitles(
        selectedFont,
        wordsPerLine,
        fontSize,
        store,
        wordsPerLine,
        fontTemplate,
        showSubtitles,
        yAxisPosition,
        setYAxisPosition,
        setScriptDetails
      );
    };

    const updateFont = (font: string) => {
      setSelectedFont(font);
      generateSubtitles(
        font,
        wordsPerLine,
        fontSize,
        store,
        wordsPerLine,
        fontTemplate,
        showSubtitles,
        yAxisPosition,
        setYAxisPosition,
        setScriptDetails
      );
    };

    const handleShowSubtitles = async () => {
      if (showSubtitles) {
        await setScriptDetails((prevScript: { data: any }) => {
          const updatedScript = { ...prevScript };

          return { ...updatedScript, showSubtitles: false };
        });
        store.pages.map((page: any) => {
          const elementID = "captionText" + page.id;
          page.set({ custom: { ...page.custom, showSubtitles: false } });
          if (store.getElementById(elementID)) {
            store.getElementById(elementID).set({
              opacity: 0,
              backgroundEnabled: false,
            });
          }
        });
      } else {
        await setScriptDetails((prevScript: { data: any }) => {
          const updatedScript = { ...prevScript };

          return { ...updatedScript, showSubtitles: true };
        });
        store.pages.map((page: any) => {
          const elementID = "captionText" + page.id;
          page.set({ custom: { ...page.custom, showSubtitles: true } });
          if (store.getElementById(elementID)) {
            store.getElementById(elementID).set({
              opacity: 1,
              backgroundEnabled: true,
            });
          }
        });
      }
      setShowSubtitles(!showSubtitles);
    };
    const pickFontColor = (e: any) => {
      const fontTemplatecopy = { ...fontTemplate, fontColor: e.target.value };
      setFontTemplate(fontTemplatecopy);
      generateSubtitles(
        selectedFont,
        wordsPerLine,
        fontSize,
        store,
        wordsPerLine,
        fontTemplatecopy,
        showSubtitles,
        yAxisPosition,
        setYAxisPosition,
        setScriptDetails
      );
    };
    const pickBackgroundColor = (e: any) => {
      const fontTemplatecopy = {
        ...fontTemplate,
        backgroundColor: e.target.value,
      };
      setFontTemplate(fontTemplatecopy);
      generateSubtitles(
        selectedFont,
        wordsPerLine,
        fontSize,
        store,
        wordsPerLine,
        fontTemplatecopy,
        showSubtitles,
        yAxisPosition,
        setYAxisPosition,
        setScriptDetails
      );
    };
    const pickHiglightFont = (e: any) => {
      const fontTemplatecopy = {
        ...fontTemplate,
        highlightTextStyleColor: e.target.value,
      };
      setFontTemplate(fontTemplatecopy);
      generateSubtitles(
        selectedFont,
        wordsPerLine,
        fontSize,
        store,
        wordsPerLine,
        fontTemplatecopy,
        showSubtitles,
        yAxisPosition,
        setYAxisPosition,
        setScriptDetails
      );
    };

    const pickHighlightBackgroundColor = (e: any) => {
      const fontTemplatecopy = {
        ...fontTemplate,
        highlightTextBackground: e.target.value,
      };
      setFontTemplate(fontTemplatecopy);
      generateSubtitles(
        selectedFont,
        wordsPerLine,
        fontSize,
        store,
        wordsPerLine,
        fontTemplatecopy,
        showSubtitles,
        yAxisPosition,
        setYAxisPosition,
        setScriptDetails
      );
    };
    const {
      ScriptDetails: [scriptDetails],
    } = useGlobalContext();
    const handleShowVoiceOver = () => {
      setShowAdvanced(!showAdvanced);
    };
    const handleColorSetting = () => {
      setShowColorSetting(!showColorSetting);
    };
    //  console.log(scriptDetails)
    const baseStyle: React.CSSProperties = {
      height: '100%',
      overflow: 'auto',
      backgroundColor: 'var(--color-primary-50)',
  
    }
    return (
      <div style={baseStyle}>
        {showTryLaterMessage ? (
          <div style={{ padding: "20px", textAlign: "center" }}>
            <Label>Something went wrong</Label>
            <Button
              icon={
                regenrateButtonIcon ? <LoadingOutlined /> : <ReloadOutlined />
              }
              style={{
                background:
                  "linear-gradient(90deg, rgba(108, 61, 240, 1) 0%, rgba(140, 103, 244, 1) 80%)",
                borderColor: "transparent",
                color: "white",
                marginBottom: "10px",
              }}
              onClick={regenerateSubtitles}
            >
              Generate Captions
            </Button>
          </div>
        ) : (
          <Row gutter={[0, 4]} style={{ flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h3
                style={{
                  fontSize: "14px",
                  fontWeight: "normal",
                  marginRight: "10px",
                }}
              >
                Captions
              </h3>
              <Switch
                style={{
                  color: "var(--color-primary-600)",
                  background: "var(--color-primary-600)",
                }}
                defaultChecked={showSubtitles}
                checked={showSubtitles}
                onChange={() => handleShowSubtitles()}
              />
            </div>
            <Label>Font</Label>
            <FontPickerRow
              defaultFont={selectedFont}
              onFontChange={updateFont}
              fontSize={fontSize}
              onFontSizeChange={updateFontSize}
            />
            <Label>Caption Style</Label>
            <ChooseFontTemplatesRow
              templates={FONT_TEMPLATE}
              onSelect={setFontTemplates}
            />
            <div style={{ display: "flex", flexDirection: "row" }}>
              <h3
                style={{
                  color: "black",
                  fontSize: "14px",
                  fontWeight: 500,
                  fontFamily: "DM Sans",
                  letterSpacing: "normal",
                  marginBottom: "5px",
                }}
              >
                Color Settings
              </h3>
              {!showColorSetting ? (
                <DownCircleOutlined
                  style={{
                    fontSize: "16px",
                    marginLeft: "5px",
                    marginTop: "5px",
                  }}
                  onClick={handleColorSetting}
                />
              ) : (
                <UpCircleOutlined
                  style={{
                    fontSize: "16px",
                    marginLeft: "5px",
                    marginTop: "5px",
                  }}
                  onClick={handleColorSetting}
                />
              )}
            </div>
            <div className={"color-setting"}>
              <div
                className={`colorSetting-d ${
                  showColorSetting ? "visible" : ""
                }`}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "baseline",
                  }}
                >
                  <Label>Font color</Label>
                  <div style={{ padding: "10px" }}>
                    <input
                      type="color"
                      value={fontTemplate.fontColor}
                      onChange={pickFontColor}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "baseline",
                  }}
                >
                  <Label>Highlight Text color</Label>
                  <div style={{ padding: "10px" }}>
                    <input
                      type="color"
                      value={fontTemplate.highlightTextStyleColor}
                      onChange={pickHiglightFont}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "baseline",
                  }}
                >
                  <Label>Background color</Label>
                  <div style={{ padding: "10px" }}>
                    <input
                      type="color"
                      value={fontTemplate.backgroundColor}
                      onChange={pickBackgroundColor}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "baseline",
                  }}
                >
                  <Label>Highlight Background color</Label>
                  <div style={{ padding: "10px" }}>
                    <input
                      type="color"
                      value={fontTemplate.highlightTextBackground}
                      onChange={pickHighlightBackgroundColor}
                    />
                  </div>
                </div>
              </div>
            </div>
            <Tooltip title={"Adjust the words per line for captions."}>
              <Label>Words Per Line</Label>
            </Tooltip>
            <WordSlider
              wordsPerLine={wordsPerLine}
              onFinish={updateWordsPerLine}
            />
            <Label>Position</Label>
            <PositionSliderRow
              yAxis={yAxisPosition}
              setYAxis={updateSubtitleYAxis}
              maxYAxis={store.height}
            />
            <div style={{ display: "flex", flexDirection: "row" }}>
              <h3
                style={{
                  color: "black",
                  fontSize: "14px",
                  fontWeight: 500,
                  fontFamily: "DM Sans",
                  letterSpacing: "normal",
                  marginBottom: "5px",
                }}
              >
                Advanced Settings
              </h3>
              {!showAdvanced ? (
                <DownCircleOutlined
                  style={{
                    fontSize: "16px",
                    marginLeft: "5px",
                    marginTop: "5px",
                  }}
                  onClick={handleShowVoiceOver}
                />
              ) : (
                <UpCircleOutlined
                  style={{
                    fontSize: "16px",
                    marginLeft: "5px",
                    marginTop: "5px",
                  }}
                  onClick={handleShowVoiceOver}
                />
              )}
            </div>
            <div className={"advanced-wrapper"}>
              <div className={`advanced-d ${showAdvanced ? "visible" : ""}`}>
                <Alert
                  message={
                    <>
                      <span
                        style={{
                          marginBottom: "0px",
                        }}
                      >
                        Use this panel only for correcting caption misspellings.
                        For changing the entire caption, please update in the{" "}
                        <Button
                          type="link"
                          style={{
                            fontSize: "10px",
                            padding: "0px",
                            margin: "0px",
                            height: 0,
                          }}
                          onClick={() => setActiveComponent("audio")}
                        >
                          Voice Overs section
                        </Button>
                      </span>
                    </>
                  }
                  type="warning"
                  style={{
                    fontSize: "10px",
                  }}
                />
                {scriptDetails.data.map((scene: any, index: number) => (
                  // <>ff</>
                  <EditCaptions
                    key={index}
                    store={store}
                    words={scene?.captions?.[0]?.words || []}
                    index={index}
                    isActive={captionsActiveIndex === index}
                    onIconClick={() => handleIconClick(index)}
                  />
                ))}
              </div>
            </div>
          </Row>
        )}
      </div>
    );
  }
);

const Label = ({ children }: { children: React.ReactNode }) => {
  return (
    <h3
      style={{
        color: "black",
        fontSize: "14px",
        fontWeight: 500,
        fontFamily: "DM Sans",
        letterSpacing: "normal",
        marginBottom: "10px",
      }}
    >
      {children}
    </h3>
  );
};

CaptionsPanel.displayName = "CaptionsPanel";

export const CustomCaptionPolotno = {
  name: "caption-panel",
  Tab: (props: any) => (
    <SectionTab name="Caption" {...props}>
      <Message01Icon className="sidebar-panel-tab-icon" />
    </SectionTab>
  ),
  Panel: CaptionsPanel,
};
