import {
  LoadingOutlined,
  DownCircleOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
  UpCircleOutlined,
  UploadOutlined,
  DeleteOutlined,
  AudioOutlined,
} from '@ant-design/icons';
import { getAudioDurationInSeconds } from '@remotion/media-utils';
import { useGetVoices } from '../../api/elevenLabs/getVoices';
import { categorisedMusicList, whisperAiVoiceList } from '../../data/music';
import {
  Button,
  InputNumberProps,
  Row,
  Col,
  Select,
  Slider,
  SliderSingleProps,
  Switch,
  Tag,
  message,
  Upload,
  Input,
  Tooltip,
  Popconfirm,
} from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useGlobalContext } from '../../context/globalContext';
import { AiVoiceRow } from '../video/customVideoTimeline/cardRows/aiVoiceRow';
import './audioPanel.less';
import { generateUniqueId } from '../../shared/utils/core';
import { aiVoicesResponse } from '../../data/aiVoiceResponse';
import { formatDuration } from '../../shared/utils/convertSecs';
import { prefetchAsset } from '../../shared/utils/remotion';
import { IS_FREE_PLATFORM } from '../../config';
import { getDomainID, getToken } from '../../config/config';
import {
  aiTextToSpeech,
  generateCaptions,
  getPrevNextText,
} from '../../shared/utils/VideoGeneration/audioGeneration';
import { TranslateAudio } from '../translate/translateAudio';
import {
  creditsDeduct,
  getUserDetails,
  voiceCloneAPI,
} from '@src/shared/utils/VideoGeneration/helperFunctions/mediaSearch';
import { warning } from '@remix-run/router/dist/history';
import {
  deleteClonedVoice,
  getClonedVoices,
  saveClonedVoices,
} from '@src/shared/utils/VideoGeneration/helperFunctions/voiceCloneApi';
import { uploadFile } from '@src/shared/utils/VideoGeneration/uploadAsset';
import { useGetAssets } from '@src/api/useGetAssets';
import { useGetBGMusic } from '@src/api/useGetBgMusic';
import { voiceFilter } from '@src/shared/utils/aiVoiceFilter';
import { VoiceFilterModal } from './audioFilterModal';
import { SectionTab } from 'polotno/side-panel';
import { VolumeHighIcon } from '../polotno/component/hugeIcons/hugeIcons';
const { Option } = Select;

interface AudioSidebarProps {
  store: any;
}

interface MusicDetails {
  30: { PAS?: string[] };
}

interface ClonedVoiceData {
  user_id: string;
  voice_name: string | null;
  createdAt: string;
  id: string;
  voice_id: string | null;
  updatedAt: string | null;
}

const labelTypeColors: { [key: string]: string } = {
  accent: 'magenta',
  description: 'green',
  age: 'cyan',
  gender: 'volcano',
  'use case': 'blue',
};
export const AudioSidebar = ({ store }: AudioSidebarProps) => {
  const {
    BackgroundMusic: [backgroundMusic, setBackgroundMusic],
    UserDetail: [userDetails, setUserDetails],
    ScriptDetails: [scriptDetails, setScriptDetails],
    AudioFade: [audioFade, setAudioFade],
    VideoStyle: [videoStyle, setVideoStyle],
    CurrentUserData: [currentUserData, setCurrentUserData],
    PreviewLoader: [isPreviewLoading, setIsPreviewLoading],
    ActiveSidePanel: [, setActiveSidePanel],
  } = useGlobalContext();
  useEffect(() => {
    setActiveSidePanel(store.openedSidePanel);
  }, []);
  const [selectedIndustryName, setSelectedIndustryName] = useState(
    `${scriptDetails?.backgroundMusic?.industry}`
  );
  const [selectedMusicUrl, setSelectedMusicUrl] = useState(
    `${scriptDetails?.backgroundMusic?.url}`
  );
  const [selectedMusicName, setSelectedMusicName] = useState(
    `${scriptDetails?.backgroundMusic?.name}`
  );
  const [playingUrl] = useState('');
  const [showVoiceOver, setShowVoiceOver] = useState(false);
  const [showAdvanedVoiceSetting, setshowAdvanedVoiceSetting] = useState(false);
  const [showAdvanedBackgroundSetting, setShowAdvanedBackgroundSetting] = useState(false);

  const [selectedVoiceName, setSelectedVoiceName] = useState(
    `${scriptDetails.data[0].name || 'Custom'}`
  );
  const [backgroundVolume, setBackgroundVolume] = useState(
    (scriptDetails?.backgroundMusic?.backgroundVolume ?? 0.1) * 100
  );
  const [aiVoiceVolume, setAiVoiceVolume] = useState(
    (scriptDetails?.aiVoiceVolume ?? 1) * 100
  );
  const [aiVoiceSpeed, setAiVoiceSpeed] = useState(
    scriptDetails?.aiVoiceSpeed ?? 1
  );
  const [voiceChanging, setVoiceChanging] = useState(false);
  const [translateloading, setTranslateloading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [backgroundFileList, setBackgroundFileList] = useState([]);
  const [IsCloning, setIsCloning] = useState(false);
  const [IsBackgroundUploading, setIsBackgroundUploading] = useState(false);
  const [myMusics, setMyMusics] = useState<any>([]);
  const [isSizeExceeded, setIsSizeExceeded] = useState(false);
  const formatter: NonNullable<SliderSingleProps['tooltip']>['formatter'] = (
    value: any
  ) => `${value}%`;
  const voiceSpeedformatter: NonNullable<
    SliderSingleProps['tooltip']
  >['formatter'] = (value) => `${value} x`;

  const [voiceName, setVoiceName] = useState('');
  const [backgroundMusicName, setBackgroundMusicName] = useState('');

  const [ClonedVoiceData, setClonedVoiceData] = useState<ClonedVoiceData[]>([]);
  const [customVoiceCloning, setCustomVoiceCloning] = useState(false);
  // const speedformatter: NonNullable<SliderSingleProps['tooltip']>['formatter'] = (value: any) => `${value}x`
  const speedformatter = (value: any) => `${value}x`;
  // Ref to the audio player
  // const audioRef = React.createRef<HTMLAudioElement>()
  const audioRef = useRef<HTMLAudioElement>(null);

  const [currentPlayingTrack, setCurrentPlayingTrack] = useState<string | null>(
    null
  );
  const { data: aiVoice, isLoading, isSuccess } = useGetVoices();

  const {
    data: backgroundMusicData,
    isError,
    error,
    refetch: refetchaudio,
  } = useGetBGMusic(1, 'audio');

  useEffect(() => {
    if (
      backgroundMusicData &&
      backgroundMusicData.items &&
      backgroundMusicData.items.length > 0
    ) {
   
      // setMyMusics([...myMusics, ...backgroundMusicData.items]);
      setMyMusics([...backgroundMusicData.items]);
    }
  }, [backgroundMusicData]);

  const handlePlayAudio = (previewUrl: string) => {
    if (audioRef.current) {
      console.log('playing');
      audioRef.current.src = previewUrl;

     
      audioRef.current
        .play()
        .catch((e) => console.error('Playback was interrupted:', e));
      setCurrentPlayingTrack(previewUrl);
    }
  };

  // Pause audio handler
  const handlePauseAudio = () => {
    if (audioRef.current && !audioRef.current.paused) {
      audioRef.current.pause();
      setCurrentPlayingTrack(null);
    }
  };

  const togglePlayPause = (value: string) => {
    const [industry, musicUrl, name] = value.split('@@@');

    if (currentPlayingTrack === musicUrl) {
      handlePauseAudio();
    } else {
      handlePlayAudio(musicUrl);
    }
  };

  const onVoiceOverVolumeChange = async (newValue: number) => {
    setAiVoiceVolume(newValue);
    await setScriptDetails((prevScript: { data: any }) => {
      const updatedScript = { ...prevScript };

      return { ...updatedScript, aiVoiceVolume: newValue / 100 };
    });
  };

  const onVoiceSpeedChange = async (newValue: number) => {
    setAiVoiceSpeed(newValue);
    await setScriptDetails((prevScript: { data: any }) => {
      const updatedScript = { ...prevScript };

      return { ...updatedScript, aiVoiceSpeed: newValue };
    });
  };

  const onBackgroundVolumeChange = async (newValue: number) => {
    setBackgroundVolume(newValue as number);

    await setScriptDetails((prevScript: { data: any }) => {
      const updatedScript: any = { ...prevScript };

      return {
        ...updatedScript,
        backgroundMusic: {
          ...updatedScript.backgroundMusic,
          backgroundVolume: newValue / 100,
        },
      };
    });
  };
  const handleFadeInOut = (checked: any, event: any) => {
    // setAudioFade(checked)
    setScriptDetails((prevScript: { data: any }) => {
      const updatedScript = { ...prevScript };

      return { ...updatedScript, audioFade: checked };
    });
  };
  const handleBackgroundMusicChange = async (value: string) => {
    const [industryName, musicUrl, name] = value.split('@@@');
   
    if (currentPlayingTrack === musicUrl) {
      handlePauseAudio();
    }

    setSelectedIndustryName(industryName);
    setSelectedMusicUrl(musicUrl);
    setSelectedMusicName(name);

    const backgroundAudioDuration = await getAudioDurationInSeconds(musicUrl);
    // setBackgroundMusic({ url: musicUrl, duration: backgroundAudioDuration, industry: industryName, name })

    const backgroundScript = {
      url: musicUrl,
      duration: backgroundAudioDuration,
      industry: industryName,
      name: name,
      backgroundVolume: backgroundVolume / 100,
    };

    // const backgroundAudioDuration = await getAudioDurationInSeconds(musicUrl)
    setBackgroundMusic(backgroundScript);

    await setScriptDetails((prevScript: { data: any }) => {
      const updatedScript = { ...prevScript };

      return { ...updatedScript, backgroundMusic: backgroundScript };
    });
  };
  const aiVoiceDropDown = [
    {
      label: 'AI Voices',
      options: whisperAiVoiceList.map((aiVoice) => ({
        label: <span>{aiVoice.label}</span>,
        value: aiVoice.value,
      })),
    },
  ];

  const subscriptionPlan = currentUserData?.subscription_plan;
  // const subscriptionPlan = 'quickads_tier4'
  if (
    subscriptionPlan !== 'free' &&
    subscriptionPlan !== 'quickads_tier2' &&
    subscriptionPlan !== 'quickads_tier1'
  ) {
    aiVoiceDropDown.push({
      label: 'Premium AI Voices',
      options: aiVoice?.voices
        ?.filter((voice: any) => voice.category !== 'cloned') // Filter voices based on category
        .map((voice: any) => ({
          label: (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>{voice.name}</span>
                <Button
                  type='text'
                  icon={<PlayCircleOutlined />}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent the select dropdown from closing
                    handlePlayAudio(voice.preview_url);
                  }}
                />
              </div>
              <div className='tag-container'>
                {Object.entries(voice.labels).map(([key, value]) => (
                  <Tag color={labelTypeColors[key] || 'default'} key={key}>
                    {`${value}`}
                  </Tag>
                ))}
              </div>
            </div>
          ),
          value: voice.voice_id, // Value for each option
        })),
    });
  } 
  // else {
  //   aiVoiceDropDown.push({
  //     label: 'Premium AI Voices',
  //     options: aiVoice?.voices
  //       ?.filter((voice: any) => voice.category !== 'cloned') // Filter voices based on category
  //       .map((voice: any) => ({
  //         label: (
  //           <div
  //             style={{
  //               display: 'flex',
  //               justifyContent: 'space-between',
  //               flexDirection: 'column',
  //             }}
  //           >
  //             <div style={{ display: 'flex', alignItems: 'center' }}>
  //               <span>{voice.name}</span>
  //               <Button
  //                 type='text'
  //                 icon={<PlayCircleOutlined />}
  //                 onClick={(e) => {
  //                   e.stopPropagation(); // Prevent the select dropdown from closing
  //                   handlePlayAudio(voice.preview_url);
  //                 }}
  //               />
  //             </div>
  //             <div className='tag-container'>
  //               {Object.entries(voice.labels).map(([key, value]) => (
  //                 <Tag color={labelTypeColors[key] || 'default'} key={key}>
  //                   {`${value}`}
  //                 </Tag>
  //               ))}
  //             </div>
  //           </div>
  //         ),
  //         value: voice.voice_id, // Value for each option
  //       })),
  //   });
  // }
  const playVoiceSample = async (
    text: string,
    aiVoiceName: string,
    isElevenApi: boolean,
    elevenlabsVoiceId: string | undefined,
    index: number,
    Similarity: number,
    Stability: number
  ): Promise<any> => {
    try {
      videoStyle !== 'Burst' && store.selectPage(store.pages[index].id);
      let domainId;
      let token;

      if (IS_FREE_PLATFORM === 'false') {
        domainId = getDomainID();
        token = getToken();
      }
      const Prevnext = getPrevNextText(scriptDetails.data, index);
      const audioUrl = await aiTextToSpeech(
        text,
        elevenlabsVoiceId,
        domainId,
        aiVoiceName,
        isElevenApi,
        token,
        Prevnext.prevText,
        Prevnext.nextText,
        50,
        75
      );
      // const audioUrl = await aiTextToSpeech(text, elevenlabsVoiceId, domainId, aiVoiceName, isElevenApi, token, '', '')
      // console.log('--before duration')
      // console.log(audioUrl)
      const duration = await getAudioDurationInSeconds(audioUrl); // Assuming `src` is the URL to your audio file
      // console.log('--after duration', duration)

      let newDuration=3000
      if (videoStyle !== 'Burst') {
        newDuration =
          duration * 1000 > store.pages[index].duration
            ? duration * 1000 + 500
            : store.pages[index].duration + 500;

        if (index === store.pages.length - 1) {
          newDuration = newDuration + 1000;
        }

        store.activePage.set({ duration: newDuration });
      }else{
        newDuration =
          duration * 1000 > store.pages[index].duration
            ? duration * 1000 + 500
            : store.pages[index].duration + 500;
        newDuration = newDuration/store.pages.filter((page: any) => page.custom.scriptIndex == index).length;
        
        // store.activePage.set({ duration: newDuration });
      }

      let captionsResult: any;
      // await generateCaptions(audioUrl)
      if (text.length > 0) {
        try {
          captionsResult = await generateCaptions(
            audioUrl,
            userDetails?.purpose?.language || ''
          );
        } catch (e) {
          message.info('Could not generate captions for the audio');
        }
      } else {
        console.error('no caption');
      }

      await setScriptDetails((prevScript: { data: any }) => {
        const updatedScript = { ...prevScript };

        const updatedData = [...prevScript.data];

        updatedData[index] = {
          ...updatedData[index],
          name: aiVoiceName,
          elevenLabsAiVoiceId: elevenlabsVoiceId,
          url: audioUrl,
          isElevenApi: isElevenApi,
          // aiVoiceVolume: voiceOverVolume / 100,
          sounds: [{ url: audioUrl, audioDuration: duration }],
          captions: [{ text: text, words: captionsResult?.data?.words }],
        };

        return { ...updatedScript, data: updatedData };
      });

      if (videoStyle !== 'Burst') {
        store.pages[index].set({
          duration: newDuration,
          custom: {
            ...store.pages[index].custom,
            // aiVoiceVolume: voiceOverVolume / 100,
            sounds: [{ url: audioUrl, audioDuration: duration }],
            captions: [{ text: text, words: captionsResult?.data?.words }],
          },
        });
      }else{
        store.pages.map((page: any) => {
          if(page.custom.scriptIndex == index){

            page.set({
              duration: newDuration,
              custom: {
                ...page.custom,
                sounds: [{ url: audioUrl, audioDuration: duration }],
                captions: [{ text: text, words: captionsResult.data.words }],
              },
            });
          }
        });
      }

      await prefetchAsset(audioUrl);
      setSelectedVoiceName(aiVoiceName);
      return { audioUrl, captionsResult, duration };
    } catch (error) {
      console.error('Error making text-to-speech request:', error);
    }
  };

  const handleChangeVoice = async (
    value: string,
    similarity: number,
    stability: number
  ) => {
    setIsPreviewLoading(true);
    const getCredits = await getUserDetails();
    setVoiceChanging(true);

    if (getCredits == -1) {
      message.warning('Something went wrong!');
      setVoiceChanging(false);
      setIsPreviewLoading(false);
      return;
    }
    if (getCredits?.voice_limit < 1) {
      message.warning(
        'Voice credits have been exhausted. Please upgrade the plan to use the voice.'
      );
      setVoiceChanging(false);
      setIsPreviewLoading(false);

      return;
    }
    setIsPreviewLoading(true);
    setVoiceChanging(true);
    // console.log(scriptDetails)

    let totalDurationInSeconds = 0;
    const voice = aiVoice?.voices?.find(
      (voice: any) => voice.voice_id === value
    );
    for (let index = 0; index < scriptDetails.data.length; index++) {
      const script = scriptDetails.data[index];
      const result = await playVoiceSample(
        script.voiceOver,
        voice ? voice.name : value,
        voice ? true : false,
        voice ? value : undefined,
        index,
        50,
        75
      );
      totalDurationInSeconds = totalDurationInSeconds + result.duration;
    }
    setVoiceChanging(false);
    setIsPreviewLoading(false);

    const audioDurationInMins = Math.ceil(totalDurationInSeconds / 60);
    try {
      await creditsDeduct('voice_credits', audioDurationInMins);
    } catch {
      console.error('Something went wrong');
    }
  };

  const handleCloneVoiceChange = async (value: string) => {
    const getCredits = await getUserDetails();
    setCustomVoiceCloning(true);
    setIsPreviewLoading(true);
    if (getCredits == -1) {
      message.warning('Something went wrong!');
      setCustomVoiceCloning(false);
      setIsPreviewLoading(false);

      return;
    }
    if (getCredits?.voice_limit < 1) {
      message.warning(
        'Voice credits have been exhausted. Please upgrade the plan to use the voice.'
      );
      setCustomVoiceCloning(false);
      setIsPreviewLoading(false);

      return;
    }

    let totalDurationInSeconds = 0;
    setCustomVoiceCloning(true);
    setIsPreviewLoading(true);

    const voiceData = ClonedVoiceData?.find((voice) => voice.id === value);
    for (let index = 0; index < scriptDetails.data.length; index++) {
      const script = scriptDetails.data[index];
      const result = await playVoiceSample(
        script.voiceOver,
        voiceData?.voice_name || '',
        true,
        voiceData?.voice_id || '',
        index,
        50,
        75
      );
      totalDurationInSeconds = totalDurationInSeconds + result.duration;
    }
    setCustomVoiceCloning(false);
    setIsPreviewLoading(false);
    const audioDurationInMins = Math.ceil(totalDurationInSeconds / 60);
    creditsDeduct('voice_credits', audioDurationInMins);
  };
  // api calls

  const handleShowVoiceOver = () => {
    setShowVoiceOver(!showVoiceOver);
  };

  const handleShowAdvancedVoiceSetting = () => {
    setshowAdvanedVoiceSetting(!showAdvanedVoiceSetting);
  };
  const handleShowAdvancedBackgroundSetting = () => {
    setShowAdvanedBackgroundSetting(!showAdvanedBackgroundSetting);
  };
  setShowAdvanedBackgroundSetting
  // const { uniqueURLs, repeatedURLs } = segregatePASURLs(categorisedMusicList)

  const categories = Object.keys(categorisedMusicList);
  const options: any = {};
  const myMusicOptions: any = [];
  myMusics.map((musicUrl: any, index: number) => {
    // const musicKey = `${category}@@@${musicUrl.url}@@@${musicUrl.name}`;
    const option = {
      label: (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {/* <div style={{ flex: 1 }}>{musicUrl.name}</div> */}
          <div style={{ flex: 1 }}>{musicUrl.asset_name}</div>

          {/* <div
            style={{
              flex: '0 0 auto',
              marginLeft: '10px',
              marginRight: '2px',
            }}
          >
            {' '}
        
            {musicUrl.duration}
          </div> */}
          <Button
            type='text'
            icon={
              currentPlayingTrack === musicUrl?.audio_url ? (
                <PauseCircleOutlined />
              ) : (
                <PlayCircleOutlined />
              )
            }
            onClick={(e) => {
              e.stopPropagation();
              togglePlayPause(
                `Others@@@${musicUrl?.audio_url}@@@${musicUrl.asset_name}`
              );
            }}
          />
        </div>
      ),
      value: `Others@@@${musicUrl?.audio_url}@@@${musicUrl.asset_name}`,
    };
    myMusicOptions.push(option);
  });

  categories.map((category) => {
    const musicList = categorisedMusicList[category][60]['PAS'];
    musicList.map((musicUrl: any) => {
      const musicKey = `${category}@@@${musicUrl.url}@@@${musicUrl.name}`;
      const option = {
        label: (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ flex: 1 }}>{musicUrl.name}</div>
            <div
              style={{
                flex: '0 0 auto',
                marginLeft: '10px',
                marginRight: '2px',
              }}
            >
              {' '}
              {/* Added marginLeft */}
              {musicUrl.duration}
            </div>
            <Button
              type='text'
              icon={
                currentPlayingTrack === musicUrl?.url ? (
                  <PauseCircleOutlined />
                ) : (
                  <PlayCircleOutlined />
                )
              }
              onClick={(e) => {
                e.stopPropagation();
                togglePlayPause(musicKey);
              }}
            />
          </div>
        ),
        value: musicKey,
      };
      if (options[category]) {
        options[category].push(option);
      } else {
        options[category] = [option];
      }
    });
  });

  // ClonedVoiceData.map((voice)=>{

  // })
  const optionValues = Object.keys(options).map((option: any) => {
    return { label: option, options: options[option] };
  });

  const handleFilesAudioChange = ({ fileList }: any) => {
    if (!isSizeExceeded) setFileList(fileList);
  };
  const handleBackgroundAudioFiles = ({ fileList }: any) => {
    setBackgroundFileList(fileList);
  };
  const handleUpload = async () => {
    setIsPreviewLoading(true);
    const getCredits = await getUserDetails();
    if (getCredits == -1) {
      message.warning('Something went wrong!');

      setIsPreviewLoading(false);

      return;
    }
    if (getCredits?.voice_clones_limit < 1) {
      message.warning(
        'Voice clone credits have been exhausted. Please upgrade the plan to clone the voice.'
      );
      setIsPreviewLoading(false);
      return;
    }
    if (getCredits?.voice_limit < 1) {
      message.warning(
        'Voice credits have been exhausted. Please upgrade the plan to use the voice.'
      );

      setVoiceChanging(false);
      setIsPreviewLoading(false);

      return;
    }
    if (voiceName.length < 1) {
      message.warning('Pleae enter voice name.');
      setIsPreviewLoading(false);

      return;
    }
    setIsPreviewLoading(true);
    setIsCloning(true);
    try {
      const fileData: any = [];
      fileList.forEach((file: any) => {
        fileData.push(file.originFileObj);
        // Ensure filename is included
      });
      message.info('Cloning will take a few moments. Please wait.');
      const cloneVoiceId = await voiceCloneAPI(voiceName, '', fileData);
      let totalDurationInSeconds = 0;
      for (let index = 0; index < scriptDetails.data.length; index++) {
        const script = scriptDetails.data[index];
        const result = await playVoiceSample(
          script.voiceOver,
          voiceName,
          true,
          cloneVoiceId,
          index,
          50,
          75
        );
        totalDurationInSeconds = totalDurationInSeconds + result.duration;
      }
      setSelectedVoiceName(voiceName);
      message.success('Voice cloned successfully.');
      setIsPreviewLoading(false);

      try {
        const audioDurationInMins = Math.ceil(totalDurationInSeconds / 60);
        await saveClonedVoices(voiceName, cloneVoiceId);
        await creditsDeduct('voice_clones_credits', 1);
        await creditsDeduct('voice_credits', audioDurationInMins);
      } catch (error) {
        message.error('Something went wrong!');
      }
    } catch (error) {
      message.error('Upload failed.');
      console.error(error);
    } finally {
      setIsCloning(false);
      setIsPreviewLoading(false);
    }
  };

  const handleBackgroundUpload = async () => {
    setIsBackgroundUploading(true);
    try {
      if (backgroundMusicName.length < 1) {
        message.warning('Please enter music name');
        return;
      }
      // backgroundFileList.forEach((file: any) => {
      //   uploadFile(file.originFileObj, backgroundMusicName);
      // });
      for (const file of backgroundFileList as any) {
        await uploadFile(file.originFileObj, backgroundMusicName, 'bg');
      }
      refetchaudio();
      message.success(
        'Music uploaded successfully and available for use in the video.'
      );
      setIsBackgroundUploading(false);
      setBackgroundMusicName('');
    } catch {
   
      setIsBackgroundUploading(false);
    }
  };

  const audioFilebeforeUpload = (file: any) => {
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('File must be smaller than 2MB!');
      setIsSizeExceeded(true);
      return false;
    }
    // console.log(isLt2M)
    // return isLt2M;
    setIsSizeExceeded(false);
    return false;
  };
  const backgroundAudioFilebeforeUpload = (file: any) => {
    const isLt2M = file.size / 1024 / 1024 < 200;
 
    if (!isLt2M) {
      message.error('File must be smaller than 200mb!');
      setIsSizeExceeded(true);
      return false;
    }
    // console.log(isLt2M)
    // return isLt2M;
    setIsSizeExceeded(false);
    return false;
  };
  const handleCloneDelete = async (id: string) => {
    // e.stopPropagation();
    try {
      await deleteClonedVoice(id);
      setClonedVoiceData((prevData) =>
        prevData.filter((voice) => voice.id !== id)
      );
      message.success('Voice deleted.');
    } catch (error) {
      message.warning('There was a problem deleting the cloned voice.');
    }
  };

  const handleBackgroundSound = (e: any) => {
    setScriptDetails((prevScript: { data: any }) => {
      const updatedScript = { ...prevScript };
      return { ...updatedScript, backgroundSound: e };
    });
  };
  useEffect(() => {
    async function clonedVoices() {
      try {
        const clonedVoices = await getClonedVoices();
      
        setClonedVoiceData(clonedVoices);
      } catch {
        console.error('Failed to get clones voices.');
      }
    }
    clonedVoices();
  }, []);
  const baseStyle: React.CSSProperties = {
    height: '100%',
    overflow: 'auto',
    backgroundColor: 'var(--color-primary-50)',
  };
  return (
    <div style={baseStyle}>
      
      <Row gutter={[0, 0]}>
        <Col>
          <Tooltip title={'Change the AI voice.'}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <h3
                style={{
                  color: 'black',
                  fontSize: '14px',
                  fontWeight: 500,
                  fontFamily: 'DM Sans',
                  letterSpacing: 'normal',
                  marginBottom: '10px',
                }}
              >
                Change Voice
              </h3>
              <div style={{ padding: '8px' }}>
                {subscriptionPlan === 'free' ||
                subscriptionPlan === 'quickads_tier2' ||
                subscriptionPlan === 'quickads_tier1' ? (
                  ''
                ) : (
                  <VoiceFilterModal
                    aiVoice={aiVoice?.voices || []}
                    handleChangeVoice={handleChangeVoice}
                    selectedVoiceName={selectedVoiceName}
                  />
                )}
                {/* <VoiceFilterModal aiVoice={aiVoice?.voices || []} handleChangeVoice={handleChangeVoice} selectedVoiceName={selectedVoiceName}/> */}
              </div>
              {/* <div style={{display:'flex', justifyContent:'end', marginTop:'10px'}}>
              </div> */}
            </div>
          </Tooltip>
        </Col>
        {voiceChanging && (
          <Col>
            <LoadingOutlined
              style={{
                marginLeft: '10px',
                marginTop: '15px',
                color: 'var(--color-primary-600)',
              }}
            />
          </Col>
        )}
      </Row>

      <Select
        style={{ minWidth: '200px', width: '100%' }}
        placeholder='Select Background Music'
        optionFilterProp='children'
        onChange={(value) => handleChangeVoice(value, 50, 75)}
        value={selectedVoiceName}
        defaultValue={selectedVoiceName}
        dropdownRender={(menu) => (
          <div>
            {menu}
            <audio
              ref={audioRef}
              src={playingUrl}
              style={{ display: 'none' }}
            />
          </div>
        )}
        options={aiVoiceDropDown}
      />
    <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Tooltip title={'Can increase or decrease the speed of the AI voice.'}>
          <h3
            style={{
              color: 'black',
              fontSize: '14px',
              fontWeight: 500,
              fontFamily: 'DM Sans',
              letterSpacing: 'normal',
              marginBottom: '5px',
            }}
          >
            AI Voice Speed
          </h3>
        </Tooltip>
        <Slider
          style={{
            width: '80%',
          }}
          step={0.01}
          min={0.5}
          max={2}
          onChange={onVoiceSpeedChange}
          tooltip={{ formatter: voiceSpeedformatter }}
          defaultValue={aiVoiceSpeed}
          value={typeof aiVoiceSpeed === 'number' ? aiVoiceSpeed : 1}
        />
      </div>

      <div style={{ display: 'flex', flexDirection: 'row' }}>
          <h3
            style={{
              color: 'black',
              fontSize: '14px',
              fontWeight: 500,
              fontFamily: 'DM Sans',
              letterSpacing: 'normal',
              marginBottom: '5px',
            }}
          >
            Advanced Voice Settings
          </h3>
          {!showAdvanedVoiceSetting ? (
            <DownCircleOutlined
              style={{ fontSize: '16px', marginLeft: '5px', marginTop: '5px' }}
              onClick={handleShowAdvancedVoiceSetting}
            />
          ) : (
            <UpCircleOutlined
              style={{ fontSize: '16px', marginLeft: '5px', marginTop: '5px' }}
              onClick={handleShowAdvancedVoiceSetting}
            />
          )}
        </div>

    <div className={`voice-settings ${showAdvanedVoiceSetting ? 'visible': ''}`}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Row gutter={[0, 0]}>
          <Col>
            <Tooltip
              title={
                'Translate the audio and captions to the language you want.'
              }
            >
              <h3
                style={{
                  color: 'black',
                  fontSize: '14px',
                  fontWeight: 500,
                  fontFamily: 'DM Sans',
                  letterSpacing: 'normal',
                  marginBottom: '5px',
                }}
              >
                Translate Audio
              </h3>
            </Tooltip>
          </Col>
          {translateloading && (
            <Col>
              <LoadingOutlined
                style={{
                  marginLeft: '10px',
                  marginTop: '15px',
                  color: '#6938ef',
                }}
              />
            </Col>
          )}
        </Row>

        <TranslateAudio
          store={store}
          translateloading={translateloading}
          setTranslateloading={setTranslateloading}
          aiVoicesResponse={aiVoicesResponse}
        />
      </div>

      <Row gutter={[0, 0]}>
        <Col>
          <Tooltip
            title={
              'List of all cloned voices, with the option to choose any available cloned voice.'
            }
          >
            <h3
              style={{
                color: 'black',
                fontSize: '14px',
                fontWeight: 500,
                fontFamily: 'DM Sans',
                letterSpacing: 'normal',
                marginBottom: '10px',
              }}
            >
              Cloned Voices
            </h3>
          </Tooltip>
        </Col>
        {customVoiceCloning && (
          <Col>
            <LoadingOutlined
              style={{
                marginLeft: '10px',
                marginTop: '15px',
                color: 'var(--color-primary-600)',
              }}
            />
          </Col>
        )}
      </Row>
      <Select
        style={{ minWidth: '200px', width: '100%' }}
        placeholder='Select voice'
        optionFilterProp='children'
        onChange={handleCloneVoiceChange}
        value={selectedVoiceName}
        defaultValue={selectedVoiceName}
        disabled={customVoiceCloning}
      >
        {ClonedVoiceData.map((voice) => (
          <Option key={voice.id} value={voice.id}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <span>{voice.voice_name || voice.id || ''}</span>
              {/* <Popconfirm
              title='Are you sure you want to delete this option?'
              onConfirm={(e:any) => { 
                e.stopPropagation() 
                handleCloneDelete(voice.id)
              }}
              okText='Yes'
              cancelText='No'
            > */}
              <Button
                type='link'
                icon={<DeleteOutlined />}
                style={{ padding: 0 }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleCloneDelete(voice.id);
                }}
              />
              {/* </Popconfirm> */}
            </div>
          </Option>
        ))}
      </Select>

      <Row gutter={[0, 0]}>
        <Col>
          <Tooltip
            title={
              'Clone your desired voice by entering the name and uploading the voice sample.'
            }
          >
            <h3
              style={{
                color: 'black',
                fontSize: '14px',
                fontWeight: 500,
                fontFamily: 'DM Sans',
                letterSpacing: 'normal',
                marginBottom: '10px',
              }}
            >
              Clone Voice
            </h3>
          </Tooltip>
        </Col>

        {/* <Col>
        <LoadingOutlined
              style={{
                marginLeft: '10px',
                marginTop: '15px',
                color: 'var(--color-primary-600)',
              }}
            />
             </Col> */}
      </Row>
      <Input
        placeholder='Enter Voice Name'
        defaultValue={voiceName}
        style={{
          width: '100%',
          marginRight: '10px',
        }}
        onChange={(e) => setVoiceName(e.target.value)}
        className='hoverable-text-box' // Apply the CSS class here
      />
      <Upload
        multiple
        fileList={fileList}
        onChange={handleFilesAudioChange}
        beforeUpload={audioFilebeforeUpload} // Prevent automatic upload
        accept='audio/*'
        maxCount={3}
      >
        <Button icon={<UploadOutlined />}>Upload Files</Button>
      </Upload>
      {/* <input type='file' name='file' onChange={handleFilesAudioChangeTwo} multiple/> */}
      <Popconfirm
        title='Confirm'
        description='Confirm that you have the rights to use this voice sample before uploading.'
        placement='top'
        okText='confirm'
        onConfirm={handleUpload}
      >
        <Button
          style={{ background: 'var(--gradient-primary)', color: 'white' }}
          // onClick={handleUpload}
          disabled={fileList.length === 0}
          loading={IsCloning}
        >
          Clone
        </Button>
      </Popconfirm>

      </div>

      <Row gutter={[0, 4]} style={{ flexDirection: 'column' }}>
        <h3
          style={{
            color: 'black',
            fontSize: '14px',
            fontWeight: 500,
            fontFamily: 'DM Sans',
            letterSpacing: 'normal',
            marginBottom: '10px',
          }}
        >
          Background Music
        </h3>
        <Select
          style={{ minWidth: '200px', width: '100%' }}
          placeholder='Select Background Music'
          optionFilterProp='children'
          onChange={handleBackgroundMusicChange}
          value={selectedMusicName}
          dropdownRender={(menu) => (
            <div>
              {menu}
              <audio
                ref={audioRef}
                src={playingUrl}
                style={{ display: 'none' }}
              />
            </div>
          )}
          options={optionValues}
        />
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <h3
            style={{
              color: 'black',
              fontSize: '14px',
              fontWeight: 500,
              fontFamily: 'DM Sans',
              letterSpacing: 'normal',
              marginBottom: '5px',
            }}
          >
            Advanced Background Music Settings
          </h3>
          {!showAdvanedBackgroundSetting ? (
            <DownCircleOutlined
              style={{ fontSize: '16px', marginLeft: '5px', marginTop: '5px' }}
              onClick={handleShowAdvancedBackgroundSetting}
            />
          ) : (
            <UpCircleOutlined
              style={{ fontSize: '16px', marginLeft: '5px', marginTop: '5px' }}
              onClick={handleShowAdvancedBackgroundSetting}
            />
          )}
        </div>
        <div className={`background-settings ${showAdvanedBackgroundSetting ? 'visible': ''}`}>
        <h3
          style={{
            color: 'black',
            fontSize: '14px',
            fontWeight: 500,
            fontFamily: 'DM Sans',
            letterSpacing: 'normal',
            marginBottom: '10px',
          }}
        >
          My Music
        </h3>
        <Select
          style={{ minWidth: '200px', width: '100%' }}
          placeholder='Select Background Music'
          optionFilterProp='children'
          onChange={handleBackgroundMusicChange}
          value={selectedMusicName}
          dropdownRender={(menu) => (
            <div>
              {menu}
              <audio
                ref={audioRef}
                src={playingUrl}
                style={{ display: 'none' }}
              />
            </div>
          )}
          // options={myMusicOptions}
        >
          {myMusicOptions.map((option: any, index: number) => (
            <Option key={index} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
        <div
          style={{
            display: 'flex',
            // justifyContent: 'space-between',
            // alignItems: 'baseline',
            flexDirection: 'row',
          }}
        >
          <h3
            style={{
              fontFamily: 'DM Sans',
              fontSize: '14px',
              fontWeight: 'normal',
              marginRight: '15px',
            }}
          >
            Background Music
          </h3>
          <Switch
            defaultChecked={scriptDetails?.backgroundSound ?? true}
            value={scriptDetails?.backgroundSound}
            onChange={(e) => handleBackgroundSound(e)}
            style={{ marginTop: '10px', width: 'fit-content' }}
          />
        </div>

        <Col>
          <Tooltip
            title={
              'Choose your desired music by entering the name and uploading the music file.'
            }
          >
            <h3
              style={{
                color: 'black',
                fontSize: '14px',
                fontWeight: 500,
                fontFamily: 'DM Sans',
                letterSpacing: 'normal',
                marginBottom: '10px',
              }}
            >
              Upload Music
            </h3>
          </Tooltip>
        </Col>
        <Input
          placeholder='Enter Music Name'
          defaultValue={backgroundMusicName}
          style={{
            // width: '100%',
            marginRight: '10px',
          }}
          onChange={(e) => setBackgroundMusicName(e.target.value)}
          className='hoverable-text-box' // Apply the CSS class here
        />
        <Upload
          fileList={backgroundFileList}
          onChange={handleBackgroundAudioFiles}
          beforeUpload={backgroundAudioFilebeforeUpload} // Prevent automatic upload
          accept='audio/*'
          // maxCount={1}
        >
          <Button icon={<UploadOutlined />}>Upload File</Button>
        </Upload>
        <Button
          style={{
            background: 'var(--gradient-primary)',
            color: 'white',
            width: '100%',
            marginTop: '2px',
          }}
          onClick={handleBackgroundUpload}
          disabled={backgroundFileList.length === 0}
          loading={IsBackgroundUploading}
        >
          Upload
        </Button>

        <p
          style={{
            color: 'black',
            fontSize: '14px',
            fontWeight: 500,
            fontFamily: 'DM Sans',
            letterSpacing: 'normal',
            marginTop: '10px',
          }}
        >
          Background Music Volume
        </p>
        <Slider
          style={{
            width: '80%',
          }}
          min={1}
          max={100}
          onChange={onBackgroundVolumeChange}
          tooltip={{ formatter }}
          defaultValue={backgroundVolume}
          value={typeof backgroundVolume === 'number' ? backgroundVolume : 0}
        />
</div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Tooltip
            title={
              'Audio fade-in and fade-out are used to lower the background sound when the AI voice is playing.'
            }
          >
            <h3
              style={{
                color: 'black',
                fontSize: '14px',
                fontWeight: 500,
                fontFamily: 'DM Sans',
                letterSpacing: 'normal',
                marginBottom: '5px',
              }}
            >
              Fade Audio In/Out
            </h3>
          </Tooltip>

          <Switch
            size='small'
            style={{ marginTop: '15px', marginLeft: '10px' }}
            onChange={handleFadeInOut}
            defaultChecked={scriptDetails?.audioFade || false}
            value={scriptDetails?.audioFade}
          />
        </div>

        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <h3
            style={{
              color: 'black',
              fontSize: '14px',
              fontWeight: 500,
              fontFamily: 'DM Sans',
              letterSpacing: 'normal',
              marginBottom: '5px',
            }}
          >
            Voice Overs
          </h3>
          {!showVoiceOver ? (
            <DownCircleOutlined
              style={{ fontSize: '16px', marginLeft: '5px', marginTop: '5px' }}
              onClick={handleShowVoiceOver}
            />
          ) : (
            <UpCircleOutlined
              style={{ fontSize: '16px', marginLeft: '5px', marginTop: '5px' }}
              onClick={handleShowVoiceOver}
            />
          )}
        </div>
        <div className={'voice-overs-wrapper'}>
          <div className={`voice-overs ${showVoiceOver ? 'visible' : ''}`}>
            <p
              style={{
                color: 'black',
                fontSize: '13px',
                fontWeight: 500,
                fontFamily: 'DM Sans',
                letterSpacing: 'normal',
                marginTop: '10px',
              }}
            >
              Voice Over Volume
            </p>
            <Slider
              style={{
                width: '80%',
              }}
              min={0}
              max={100}
              onChange={onVoiceOverVolumeChange}
              tooltip={{ formatter }}
              defaultValue={aiVoiceVolume}
              value={typeof aiVoiceVolume === 'number' ? aiVoiceVolume : 0}
            />
            {scriptDetails.data.map((page: any, index: any) => {
              return (
                <div key={index}>
                  {isSuccess && aiVoice?.voices?.length > 0 ? (
                    <AiVoiceRow
                      index={index}
                      aiVoice={aiVoice}
                      store={store}
                      voiceOverVolume={aiVoiceVolume}
                      globalVoice={selectedVoiceName}
                      showPanel={true}
                    />
                  ) : (
                    <AiVoiceRow
                      index={index}
                      aiVoice={aiVoicesResponse}
                      store={store}
                      voiceOverVolume={aiVoiceVolume}
                      globalVoice={selectedVoiceName}
                      showPanel={true}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </Row>
    </div>
  );
};

export const CustomAudioPolotno = {
  name: 'audio-panel',
  Tab: (props: any) => (
    <SectionTab name='Audio' {...props}>
      <VolumeHighIcon className='sidebar-panel-tab-icon' />
    </SectionTab>
  ),
  Panel: AudioSidebar,
};
