import { getDomainID, qaBackendApiInstance } from "@src/config/config"
import { message } from "antd"
import { GettyQualityImage, GettyVideoDownloadSizes, getUserDetails } from "./mediaSearch"
import extractIdFromUrl from "../../exractIdFromGettyUrl"
import { trackEvent } from "@src/lib/mixpanel"
import { burstImageTransitionFunctions } from "@src/data/transitions"
import { addIdsToHtmlFromJson, extractPagesFromHtml } from "../../remotion/htmlProcessing"
import { millisecondsToFrames } from "../../remotion"
import { generateUniqueId } from "../../core"
import { getElementIdJsonPageTavusPayload } from "../../findReplicaJson"

export async function downloadAsync(videos_raw_data:any, json_body:any, remotion_props:any,
    project_id:any
){
    const token = localStorage.getItem('accessToken')
    const postBody={
        videos_raw_data:videos_raw_data,
        json_body:json_body,
        remotion_props:remotion_props,
        project_id:Number(project_id),
        domain_id:getDomainID()
    }
    try{
        const reponse = await qaBackendApiInstance.post('video/gen-ai-avatar-video',
            postBody,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
        )
        return reponse.data

    }
    catch(error){
        return -1
    }
}

export async function checkRenderStatus(task_id:any){
    try {
        const response = await qaBackendApiInstance.get(
          `/video/check-ai-avatar-video/${task_id}`
        );
        if (response.status === 200) {
          return response.data;
        } else {
          return undefined;
        }
      } catch {
        return undefined;
      }
}
const assetIds: any[] = [];

let waterMarkedAsset: { id: any; src: any }[] = [];
const updateVideos = async (store:any) => {
    const currentDate = new Date().toISOString().split("T")[0];

    for (const page of store.pages) {
      for (const child of page.children) {
        if (child.type === "video") {
          const element = store.getElementById(child.id);
          const obj = { id: child.id, src: child.src };
          waterMarkedAsset.push(obj);
          const assetId = extractIdFromUrl(child.src);
          if (element && assetId) {
            if (child.custom) {
              try {
               
                const qualityUrl = await GettyVideoDownloadSizes(assetId);
                if (qualityUrl) {
                  element.set({ src: qualityUrl });
                  assetIds.push({
                    asset_id: assetId,
                    quantity: 1,
                    usage_date: currentDate,
                  });
                }
              } catch (error) {
                console.error(
                  `Failed to get video quality URL for id ${child.custom.gettyId}: `,
                  error
                );
              }
            }
          }
        } else if (child.type === "image") {
          const element = store.getElementById(child.id);
         
          const assetId = extractIdFromUrl(child.src);
          if (element && assetId) {
            if (child.custom) {
              try {
                const qualityUrl = await GettyQualityImage(assetId || "");

                if (qualityUrl) {
                  element.set({ src: qualityUrl });
                  assetIds.push({
                    asset_id: assetId,
                    quantity: 1,
                    usage_date: currentDate,
                  });
                }
              } catch (error) {
                console.error(
                  `Failed to get video quality URL for id ${child.custom.gettyId}: `,
                  error
                );
              }
            }
          }
        }
      }
    }
  };
export const avatarRendering=async(store:any, scriptDetails:any, userDetails:any, videoStyle:any,transitionAnimation:any, projectID:any)=>{

  const getCredits = await getUserDetails();
    
    if (getCredits == -1) {
      message.warning("Something went wrong!");
    //   setIsVideoRendering(false)
    
      return false;
    }
    if (getCredits?.video_limit < 1) {
    //   message.warning(
    //     "Video credits have been exhausted. Please upgrade the plan to download the video."
    //   );
    //   setIsVideoRendering(false)
    message.warning("Video Download limit reached")
      return false;
    }
   

    // console.log( waterMarkedAsset )
    if (waterMarkedAsset.length < 1) {
      await updateVideos(store);
    }
    // trackEvent("Video", {
    //   name: currentUserData?.name,
    //   email: currentUserData?.email,
    //   message: `Download Started for project id: ${projectID}`,
    //   type: "INFO",
    //   submodule: "async_download",
    // });
    const jsonData = await store.toJSON()
    const burstImagesTransitionAnimationArray = [];
    if (videoStyle === "Burst") {
      for (let i = 0; i < scriptDetails.data.length; i++) {
        const innerArray = [];

        for (let j = 0; j < scriptDetails.data[i].images.length; j++) {
          const randomFunction =
            burstImageTransitionFunctions[
              Math.floor(Math.random() * burstImageTransitionFunctions.length)
            ];
          // innerArray.push(randomFunction.value)
          innerArray.push(transitionAnimation[0].animation);
        }
        burstImagesTransitionAnimationArray.push(innerArray);
      }
    }
    // const promises = await Promise.all(
    //   jsonData.pages.map(async (page: any) => {
    //     await prefetchAsset(page?.custom?.sounds[0]?.url);
      
    //     await Promise.all(
    //       page.children.map(async (child: any) =>
    //         (child.type === "video" || child.type === "image") && child.src
    //           ? child.type === "video" && preloadVideo(child.src)
    //           : Promise.resolve()
    //       )
    //     );
    //   })
    // );
       /* this is done so that the caption text on polotno does not show up in the downloaded video.
  the text shown on polotno when captions is enabled is only shown visually for the user*/
  store.pages.map((page: any) => {
    const elementID = "captionText" + page.id;
    if (store.getElementById(elementID)) {
      store.getElementById(elementID).set({
        opacity: 0,
      });
    }
  });
    // getting the html code
    const result = await store.toHTML();
    // converting the html code into different pages so it reflects each page in json
    const htmlCode = extractPagesFromHtml(result);
    const htmlCodeWithTags = await addIdsToHtmlFromJson(htmlCode, jsonData);
    const data = htmlCodeWithTags;
    const aiAvatar = userDetails?.purpose?.aiAvatar;
    const duration = await millisecondsToFrames(store.duration, 30);
   
    // getting transition duration from the global context
    const transitionDurationInFrames = await transitionAnimation[0].duration;
     // getting the transition function from the global context
     const transitionAnimationProp = await transitionAnimation[0].animation;

     const isDownload = false;
     if (scriptDetails?.showSubtitles) {
      store.pages.map((page: any) => {
        const elementID = "captionText" + page.id;
        if (store.getElementById(elementID)) {
          store.getElementById(elementID).set({
            opacity: 1,
          });
        }
      });
    }
    const roomId = generateUniqueId();
    // setGenerateUniqueRoomId(roomId)
    const remotionProps={
      data,
      jsonData,
      duration,
      transitionDurationInFrames,
      transitionAnimationProp,
      isDownload,
      scriptDetails,
      burstImagesTransitionAnimationArray,
      videoStyle,
      isFree: true,
      roomId,
      aiAvatar: aiAvatar,
    }
    const videoRawData=await getElementIdJsonPageTavusPayload(jsonData, scriptDetails, userDetails)
   if(videoRawData?.length > 0){
    if(getCredits?.ugc_limit < 1){
      message.warning("UGC Download limit reached, Upgrade your plan to download more videos")
      return false;
    }
   }
   const reponse = await downloadAsync(videoRawData, jsonData, remotionProps, projectID)
   setBackToWaterMark(store)
  
    return reponse
}
function setBackToWaterMark(store:any) {
    for (const page of store.pages) {
      for (const child of page.children) {
        if (child.type === "video") {
          const element = store.getElementById(child.id);
          for (const obj of waterMarkedAsset) {
            if (obj.id === child.id) {
              element.set({ src: obj.src });
            }
          }
        }
      }
    }
    waterMarkedAsset = [];
    
  }