import { useGlobalContext } from "@src/context/globalContext";
import {
  ArrowDown01Icon,
  ArrowUp01Icon,
  CarouselHorizontalIcon,
} from "./hugeIcons/hugeIcons";
import { SectionTab } from "polotno/side-panel";
import { useEffect } from "react";

export const AdvancedPanel = ({ store }: any) => {
  const {
    ShowAdvanced: [showAdvanced, setShowAdvanced],
    ActiveSidePanel: [activeSidePanel, setActiveSidePanel],
  } = useGlobalContext();

  useEffect(() => {
    store.openSidePanel(activeSidePanel);

    // console.log(!showAdvanced);
    // setShowAdvanced(!showAdvanced);
  }, []);
  return <>Advanced</>;
};

export const CustomAdvancedPanel = {
  name: "advanced-panel",
  Tab: (props: any) => {
    const {
      ShowAdvanced: [showAdvanced, setShowAdvanced],
      ActiveSidePanel: [activeSidePanel, setActiveSidePanel],
    } = useGlobalContext();
    const advancedSectionClick = () => {
      setShowAdvanced(!showAdvanced);
    };
    return (
      <SectionTab name="Advanced" {...props} onClick={advancedSectionClick}>
        {showAdvanced && <ArrowUp01Icon />}
        {!showAdvanced && <ArrowDown01Icon />}
      </SectionTab>
    );
  },
  Panel: AdvancedPanel,
};
