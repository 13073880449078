
import axios, { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';  // Updated import for React Query
import { jwtDecode } from "jwt-decode";
import { NODE_ENV } from '@src/config';
import { getDomainID, getToken } from '@src/config/config';

let PROXY_URL: string
if (NODE_ENV === 'dev') {
    // PROXY_URL = 'https://dev.ytdl.quickads.ai/api/v1/asset';
    PROXY_URL = 'http://23.23.87.235:8001/api/v1/asset'
} else {
    PROXY_URL = 'https://ytdl.quickads.ai/api/v1/asset';
}

// Function to upload the video asset
async function mutationFn(type: string, payload: FormData, domain_id: string) {
 
    const token = getToken();
    const decoded: any = jwtDecode(token);
    const userId = decoded?.id;
    const endpoint = `${PROXY_URL}/upload-video?asset_type=${type}&domain_id=${domain_id}&user=${userId}`;
    return axios.post(endpoint, payload).then(({ data }) => data);
}

// Custom hook for uploading large assets
const useUploadLargeAsset = () => {
    const queryClient = useQueryClient();
    const domainId=getDomainID()
    const { mutate, isLoading, data } = useMutation(
        ['upload-asset'],
        ({ type, payload }: { type: string; payload: FormData }) =>
            mutationFn(type, payload, domainId),
        {
            onSuccess(data) {
                queryClient.invalidateQueries(['get-all-assets']);  // Invalidate the asset query
            },
            onError(err: AxiosError) {
                console.error(err)  // Handle errors appropriately
            },
        }
    );

    return { mutate, isLoading, data };  // Return the mutate function, loading state, and data
};

export default useUploadLargeAsset;
